<template>
    <v-container>
        <v-card>
            <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold">Reportería de avances de cierre de procesos compra</h2>
            </div>
            <v-divider />

            <div class="ml-5 mt-5 mr-5">
              <v-row align="stretch">
              <v-col cols="12" md="6" lg="3">
                <v-card style="height: 100%">
                  <v-card-title>
                    <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
                      <v-icon class="mr-1" style="vertical-align: middle;">mdi-format-list-numbered</v-icon>
                      Procesos Identificados
                    </p>
                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                    <div>
                      <span class="text-caption">Total de procesos que no estaban en contratación</span>
                      <br/>
                      <span class="text-h5 font-weight-bold">{{ totalRows }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <v-card style="height: 100%">
                  <v-card-title>
                    <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
                      <v-icon class="mr-1" style="vertical-align: middle;">mdi-format-list-numbered</v-icon>
                      Procesos Contratados
                    </p>
                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                    <div>
                      <span class="text-caption">Total procesos identificados que pasaron a contratación</span>
                      <br/>
                      <span class="text-h5 font-weight-bold">
                        {{ avance_conteo }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <v-card style="height: 100%">
                  <v-card-title>
                    <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
                      <v-icon class="mr-1" style="vertical-align: middle;">mdi-progress-clock</v-icon>
                      Procesos pendientes
                    </p>
                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                    <div>
                      <span class="text-caption text-capitalize"></span>
                      <br/>
                      <span class="text-h5 font-weight-bold">
                        {{ Number(this.totalRows - this.avance_conteo) }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <v-card style="height: 100%">
                  <v-card-title>
                    <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
                      <v-icon class="mr-1" style="vertical-align: middle;">mdi-progress-clock</v-icon>
                      Porcentaje de avance
                    </p>
                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                    <div>
                      <span class="text-caption text-capitalize"></span>
                      <br/>
                      <span class="text-h5 font-weight-bold">
                        % {{ Number(this.avance_conteo) === 0 && Number(this.totalRows) === 0 ? '0.00' : Number((this.avance_conteo/this.totalRows)*100).toFixed(2) }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

            <div class="px-4 py-4" style="position: relative;">
                <v-expansion-panels class="mt-4" v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="d-flex align-center" style="gap: 8px;">
                                <v-icon>mdi-filter-settings</v-icon>
                                <span class="text-subtitle-1 font-weight-bold text-uppercase">Filtros</span>
                            </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                            <v-divider />
                            <v-row align="center" justify="center" class="mx-4 my-4">
															<v-col cols="12" md="6" lg="3">
																<v-autocomplete
																	label="Institución"
																	:items="instituciones"
																	item-value="id"
																	item-text="nombre_completo"
																	outlined
																	hide-details
																	clearable
																	v-model="filters.id_institucion"
																	@change="getinstitucionesProcesosPublicos"
															/>
															</v-col>
                            </v-row>
                            </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <DataTableComponent 
                    :headers="headers"
                    :items="dataProcesos"
                    class="mt-8"
                    dense
                    no-gutters
										@paginar="manejarPaginacionProductosSolicitud"
										:total_registros="totalRows"
										v-models:pagina="page"
                    v-models:select="perPage"
                >
								<template  v-slot:[`item.fecha_hora_fin`]="{ item }">
									{{ moment(item.EtapaPorProcesos[0].fecha_hora_fin).format('DD/MM/YYYY')}}
								</template>
                </DataTableComponent>
            </div>
        </v-card>

        <ModalDetallesMovimientoCm 
            :is-open.sync="modalDetallesMovimientoAbierta"
            :movimiento="movimientoActivo"
        />
    </v-container>
</template>
<script>
import ModalDetallesMovimientoCm from './components/ModalDetallesMovimientoCm.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ProgresiveLoadAutocomplete } from '@/components/utils';
import TotalesConvenio from './components/TotalesConvenio.vue';
import MontosPorAnio from './components/MontosPorAnio.vue';
import TopProductos from './components/TopProductos.vue';
import { NavButton } from '@/components/utils';
import Estadisticas from './Estadisticas.vue';
import reporteriaSubastas from '@/services/reporteriaSubastas';
import moment from 'moment';


export default {
    components: { 
        NavButton, 
        DataTableComponent, 
        ProgresiveLoadAutocomplete, 
        Estadisticas, 
        ModalDetallesMovimientoCm, 
        TopProductos, 
        TotalesConvenio,
        MontosPorAnio,
    },
    data: () => ({
        panel: [0],
        headers: [
            {
							align: 'center',
							sortable: false,
							text: 'Institución solicitante',
							value: 'Institucion.nombre'
						},
						
            {
							align: 'center',
							sortable: false,
							text: 'Código Proceso',
							value: 'codigo_proceso'
						},
            {
							align: 'center',
							sortable: false,
							text: 'Nombre Proceso',
							value: 'nombre_proceso'
						},
            {
							align: 'center',
							sortable: false,
							text: 'Etapa por Proceso',
							value: 'fecha_hora_fin'
						},
            {
							align: 'center',
							sortable: false,
							text: 'Seguimiento del Proceso',
							value: 'SeguimientoProceso.nombre'
						},
        ],
        // Detalles
        modalDetallesMovimientoAbierta: false,
        movimientoActivo: null,
				dataProcesos: [],
				ctlInstituciones: [],
				filters: {
				id_institucion: null,
				},
				totalRows: 0,
				page: 1,
				perPage: 10,
        avance_conteo: null,
    }),
		computed: {
			instituciones() {
				return this.ctlInstituciones.map((institucion) => ({
					...institucion,
					nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
				}))
			},
		},
		methods: {
			// UI
			async getinstitucionesProcesosPublicos() {
				const filtros = {
            ...this.filters,
            page: this.page,
            per_page: this.perPage,
          };
				const { data, headers } = await reporteriaSubastas.institucionesProcesosPublicos(filtros);
				this.page = Number(headers.page);
				this.perPage = Number(headers.per_page);
				this.totalRows = Number(headers.total_rows);
				this.dataProcesos = data.procesos;
        this.avance_conteo = data.count;
			},
			async fetchInstituciones() {
				const response =
					await this.services.Instituciones.getCatalogoInstituciones({
						pagination: false,
					});
				if (response) {
					this.ctlInstituciones = response.data;
				}
			},
			manejarPaginacionProductosSolicitud(paginacion) {
				const { pagina, cantidad_por_pagina } = paginacion;
				this.page = pagina,
				this.perPage= cantidad_por_pagina,
				this.getinstitucionesProcesosPublicos();
			},
		},
    created() {
			this.getinstitucionesProcesosPublicos();
			this.fetchInstituciones();
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0px !important;
}

:deep(.v-item-group) {
    position: sticky;
    top: 0;
    z-index: 150;
}

@media (min-width: 960px) {
    :deep(.v-item-group) {
        top: 64px;
    }
}
</style>