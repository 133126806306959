<template>
	<v-container>
		<v-card>
			<div class="d-flex align-center px-4 py-4" style="gap: 16px;">
				<NavButton />
				<h2 class="text-h6 text-uppercase font-weight-bold">Reportería de avances de cierre de procesos compra</h2>
			</div>
			<v-divider />

			<div class="px-4 py-4" style="position: relative;">
				<v-expansion-panels class="mt-4" v-model="panel" multiple>
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="d-flex align-center" style="gap: 8px;">
								<v-icon>mdi-filter-settings</v-icon>
								<span class="text-subtitle-1 font-weight-bold text-uppercase">Filtros</span>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-divider />
							<v-row align="center" justify="center" class="mx-4 my-4">
								<v-col cols="12" md="6" lg="3">
									<v-autocomplete label="Institución" :items="instituciones" item-value="id" item-text="nombre_completo"
										outlined hide-details clearable v-model="filters.id_institucion"
										@change="getinstitucionesProcesosPublicos" />
								</v-col>
							</v-row>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				

				<v-data-table :headers="headers" :items="dataProcesos" class="mt-8" dense no-gutters
					@paginar="manejarPaginacionProductosSolicitud" :total_registros="totalRows" v-models:pagina="page"
					v-models:select="perPage">
          <template v-slot:[`item.avance`]="{ item }">
						% {{ Number(item.procesos_seguimiento) === 0 && Number(item.procesos_libres) === 0 ? '0.00' : Number((item.procesos_seguimiento /item.procesos_libres)*100).toFixed(2)  }}
					</template>
				</v-data-table>
			</div>
		</v-card>

		<ModalDetallesMovimientoCm :is-open.sync="modalDetallesMovimientoAbierta" :movimiento="movimientoActivo" />
	</v-container>
</template>
<script>
import ModalDetallesMovimientoCm from './components/ModalDetallesMovimientoCm.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ProgresiveLoadAutocomplete } from '@/components/utils';
import TotalesConvenio from './components/TotalesConvenio.vue';
import MontosPorAnio from './components/MontosPorAnio.vue';
import TopProductos from './components/TopProductos.vue';
import { NavButton } from '@/components/utils';
import Estadisticas from './Estadisticas.vue';
import reporteriaSubastas from '@/services/reporteriaSubastas';

export default {
	components: {
		NavButton,
		DataTableComponent,
		ProgresiveLoadAutocomplete,
		Estadisticas,
		ModalDetallesMovimientoCm,
		TopProductos,
		TotalesConvenio,
		MontosPorAnio,
	},
	data: () => ({
		panel: [0],
		headers: [
			{
				align: 'center',
				sortable: false,
				text: 'Institución',
				value: 'nombre'
			},
			{
				align: 'center',
				sortable: false,
				text: 'Procesos Identificados',
				value: 'procesos_libres'
			},
			{
				align: 'center',
				sortable: false,
				text: 'Procesos Contratados',
				value: 'procesos_seguimiento'
			},
			{
				align: 'center',
				sortable: false,
				text: 'Porcentaje Avance',
				value: 'avance'
			},
		],
		// Detalles
		modalDetallesMovimientoAbierta: false,
		movimientoActivo: null,
		dataProcesos: [],
		ctlInstituciones: [],
		filters: {
			id_institucion: null,
		},
		totalRows: 0,
		page: 1,
		perPage: 10,
	}),
	computed: {
		instituciones() {
			return this.ctlInstituciones.map((institucion) => ({
				...institucion,
				nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
			}))
		},
	},
	methods: {
		// UI
		async getinstitucionesProcesosPublicos() {
			const filtros = {
				...this.filters,
				page: this.page,
				per_page: this.perPage,
			};
			const { data } = await reporteriaSubastas.institucionesinstitucionesProcesosPublicos(filtros);
			this.dataProcesos = data;
		},
		async fetchInstituciones() {
			const response =
				await this.services.Instituciones.getCatalogoInstituciones({
					pagination: false,
				});
			if (response) {
				this.ctlInstituciones = response.data;
			}
		},
		manejarPaginacionProductosSolicitud(paginacion) {
			const { pagina, cantidad_por_pagina } = paginacion;
			this.page = pagina,
				this.per_page = cantidad_por_pagina,
			this.getinstitucionesProcesosPublicos();
		},
	},
	created() {
		this.getinstitucionesProcesosPublicos();
		this.fetchInstituciones();
	},
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
	padding: 0px !important;
}

:deep(.v-item-group) {
	position: sticky;
	top: 0;
	z-index: 150;
}

@media (min-width: 960px) {
	:deep(.v-item-group) {
		top: 64px;
	}
}
</style>